import React from 'react';
import Lottie from 'react-lottie';
import { useHistory } from 'react-router-dom';

import { Button, Box, Typography, Grid } from '@material-ui/core';

import checkAnimation from '../../../lotties/successCheckAncliBank.json';

import styles from './styles.module.css';

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: checkAnimation,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function Success() {
  const history = useHistory();

  function goToLogin() {
    history.push('/login');
  }

  return (
    <div className={styles.success}>
      <Grid container direction="column" alignItems="center">
        <Box mb={3}>
          <Lottie options={animationOptions} width={150} height={120} />
        </Box>

        <Box mb={3} className={styles.textContainer}>
          <Typography className={styles.biggerText}>
            Senha criada com sucesso!
          </Typography>

          <Typography className={styles.subTitle}>
            Utilize essa senha para acessar a plataforma.
          </Typography>
        </Box>
      </Grid>

      <Button onClick={goToLogin} className={styles.submit}>
        Acessar
      </Button>
    </div>
  );
}
