import React from 'react';
import {
  Button,
  IconButton,
  Typography,
  Tooltip,
  Menu,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import { Pie } from 'react-chartjs-2';
import Skeleton from 'react-loading-skeleton';
import DateFnsUtils from '@date-io/date-fns';
import MomentUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { topFivePaymentsColors } from '../../../../../../mocks/topFivePaymentsColors';
import currencyFormatter from '../../../../../../utils/currencyFormatter';
import formatDate from '../../../../../../utils/formatDate';

import FakePaymentsTopFive from './UI/FakePaymentsTopFive';
import NoContent from '../NoContent';

import styles from './styles.module.css';

function TopPayments({
  topFiveAmounts,
  topFive,
  noContentTopFive,
  loadingTopFive,
  textErrorTopFive,
  onHandleCleanFilters,
  yearMouthTopFive,
  onChangeYearMouthTopFive,
  openMenuEventTopFive,
  setOpenMenuEventTopFive,
  onButtonFilterTopFive,
  setAnimationChartPayment,
  animationTopFive,
  setAnimationTopFive,
  lastYearMouthTopFive,
  setYearMouthTopFive,
}) {
  const { topFivePayments, totalAmount } = topFive;

  const data = {
    datasets: [
      {
        data: topFiveAmounts,
        backgroundColor: [
          '#a41e1d',
          '#eb3636',
          '#e98e8e',
          '#f1d1d1',
          '#f14141',
        ],
        borderColor: ['#a41e1d', '#eb3636', '#e98e8e', '#f1d1d1', '#f14141'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    animation: {
      animateRotate: animationTopFive,
      render: false,
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.contentPayment}>
            <Typography className={styles.title}>Top 5 pagamentos</Typography>

            {loadingTopFive ? (
              <Skeleton
                color="#202020"
                highlightColor="#444"
                className={styles.skeletonOne}
              />
            ) : (
              <Typography className={styles.subTitle}>
                {currencyFormatter(totalAmount) || 0}
              </Typography>
            )}
          </div>
          <div className={styles.contentDate}>
            <Button className={styles.buttonFilterActive}>
              {formatDate(yearMouthTopFive, 'MMM YYYY')}
            </Button>

            <Tooltip title="Selecionar período">
              <Button
                className={styles.buttonEvent}
                onClick={event => {
                  setAnimationChartPayment(false);
                  setAnimationTopFive(false);
                  setOpenMenuEventTopFive(event.currentTarget);
                }}
                aria-controls="simple-menu"
                aria-haspopup="true"
              >
                <IconButton className={styles.buttonIcon}>
                  <EventIcon className={styles.icon} />
                </IconButton>
              </Button>
            </Tooltip>
          </div>

          <Menu
            id="simple-menu"
            anchorEl={openMenuEventTopFive}
            keepMounted
            open={openMenuEventTopFive}
            onClose={() => {
              setOpenMenuEventTopFive(null);
              setYearMouthTopFive(lastYearMouthTopFive);
            }}
          >
            <div className={styles.contentPeriod}>
              <div className={styles.containerPeriod}>
                <Typography>Período</Typography>
              </div>

              <div className={styles.containerYearAndMonth}>
                <div className={styles.containerDayDate}>
                  <MuiPickersUtilsProvider
                    utils={(DateFnsUtils, MomentUtils)}
                    locale="pt-br"
                  >
                    <DatePicker
                      openTo="year"
                      views={['year', 'month']}
                      label={yearMouthTopFive ? 'Selecione o período' : null}
                      placeholder="Selecione o período"
                      value={yearMouthTopFive}
                      onChange={onChangeYearMouthTopFive}
                      inputVariant="outlined"
                      style={{ width: '100%' }}
                    />
                  </MuiPickersUtilsProvider>
                </div>

                <div className={styles.buttonsGroup}>
                  <Button
                    onClick={onHandleCleanFilters}
                    className={styles.closeButton}
                  >
                    CANCELAR
                  </Button>

                  <Button
                    disabled={!yearMouthTopFive}
                    onClick={onButtonFilterTopFive}
                    className={styles.confirmButton}
                  >
                    APLICAR
                  </Button>
                </div>
              </div>
            </div>
          </Menu>
        </div>

        {loadingTopFive ? (
          <FakePaymentsTopFive />
        ) : (
          <>
            {noContentTopFive ? (
              <NoContent
                title={
                  textErrorTopFive ||
                  'Aqui será mostrado um gráfico com informações de pagamento.'
                }
                subTitle={
                  textErrorTopFive &&
                  'Verifique sua conexão, aguarde alguns instantes e tente novamente mais tarde.'
                }
              />
            ) : (
              <>
                <div className={styles.body}>
                  <div className={styles.contentDashboard}>
                    <Pie
                      data={data}
                      options={options}
                      className={styles.graphicPie}
                    />
                  </div>
                  <div className={styles.contentType}>
                    <div className={styles.listPayment}>
                      {topFivePayments?.map((item, index) => {
                        const positionTopFive = index;
                        return (
                          <div className={styles.typePayment}>
                            {topFivePaymentsColors?.map((item, index) => {
                              if (positionTopFive === index) {
                                return (
                                  <div
                                    className={styles.ballPayment}
                                    style={{ background: item.color }}
                                  />
                                );
                              }
                            })}

                            <span>NSU {item.nsu}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default TopPayments;
