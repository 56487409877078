import React from 'react';
import { useHistory } from 'react-router-dom';

import { itemsDisabledValidation } from './itemsDisabledValidation';

import { ReactComponent as HomeIcon } from '../../../../../assets/Icons/menu/home.svg';
import { ReactComponent as HomeActiveIcon } from '../../../../../assets/Icons/menu/home_active.svg';

import { ReactComponent as FinanceIcon } from '../../../../../assets/Icons/menu/attach_money.svg';
import { ReactComponent as FinanceActiveIcon } from '../../../../../assets/Icons/menu/attach_money_active.svg';

import { ReactComponent as EmployeesIcon } from '../../../../../assets/Icons/menu/group.svg';
import { ReactComponent as EmployeesActiveIcon } from '../../../../../assets/Icons/menu/group_active.svg';

import { ReactComponent as CompanyIcon } from '../../../../../assets/Icons/menu/domain.svg';
import { ReactComponent as CompanyActiveIcon } from '../../../../../assets/Icons/menu/domain_active.svg';

import { ReactComponent as ClockIcon } from '../../../../../assets/Icons/menu/schedule.svg';
import { ReactComponent as ClockActiveIcon } from '../../../../../assets/Icons/menu/schedule_active.svg';

import { ReactComponent as ConsignedIcon } from '../../../../../assets/Icons/menu/consigned.svg';
import { ReactComponent as ConsignedActiveIcon } from '../../../../../assets/Icons/menu/consigned_active.svg';

import { ReactComponent as BeeValeIcon } from '../../../../../assets/Icons/menu/beevale_logo.svg';
import { ReactComponent as BeeValeActiveIcon } from '../../../../../assets/Icons/menu/beevale_logo-active.svg';

import { ReactComponent as InsuranceIcon } from '../../../../../assets/Icons/menu/security.svg';
import { ReactComponent as InsuranceActiveIcon } from '../../../../../assets/Icons/menu/security_active.svg';

import { ReactComponent as HelpIcon } from '../../../../../assets/Icons/menu/help.svg';
import { ReactComponent as HelpActiveIcon } from '../../../../../assets/Icons/menu/help_active.svg';

import { ReactComponent as CustomerSupportIcon } from '../../../../../assets/customer-support-icon.svg';
import { ReactComponent as ExitFromPlatform } from '../../../../../assets/exit-from-plataform.svg';

export function useMainMenuOptions(
  accessProfiles,
  payrollProduct,
  safeProduct,
  isSubsidiary,
  situationPointPlan,
  handleReleaseAnticipationScreens,
  onConfirmExit,
  consigned,
  beevaleBenefits,
  accountSelected,
) {
  const history = useHistory();

  const ONLINE_SUPPORT_LINK_GENERAL =
    'https://flexucsoma.g4flex.com.br:8082/chat';
  const ONLINE_SUPPORT_LINK_FORTESPAY =
    'https://api.whatsapp.com/send?phone=558540002997&text=Quero%20iniciar%20uma%20conversa%20com%20a%20FortesPay';
  const SOMAPAY_ACADEMY_LINK = 'https://somapayacademy.club.hotmart.com/signup';
  const INTERN_INSURANCE_LINK =
    'https://wingseguros.com.br/seguro-estagiario-soma-pay/?utm_source=parceria&utm_medium=plataforma_pj_somapay&utm_campaign=somapay&utm_content=seguro_estagiario_somapay&utm_term=estagiario';
  const LIFE_INSURANCE_LINK =
    'https://wingseguros.com.br/seguro-de-vida-somapay/?utm_source=parceria&utm_medium=plataforma_pj_somapay&utm_campaign=somapay&utm_content=seguro_de_vida_somapay&utm_term=segurodevida';
  const DENTAL_INSURANCE_LINK =
    'https://wingseguros.com.br/seguro-odonto-somapay/?utm_source=parceria&utm_medium=plataforma_pj_somapay&utm_campaign=somapay&utm_content=seguro_odonto_somapay&utm_term=odonto';

  const isOptionsDisabled = itemsDisabledValidation(
    accessProfiles,
    payrollProduct,
    safeProduct,
    isSubsidiary,
    situationPointPlan,
    consigned,
    beevaleBenefits,
  );

  const invisibleOptions = {
    valuesQuery: !handleReleaseAnticipationScreens,
    valuesAnticipation: !handleReleaseAnticipationScreens,
    limitQuery: !handleReleaseAnticipationScreens,
    onlinePoint:
      process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION === 'FORTESPAY',
    manageUsers: true,
    exit: window.innerWidth >= 960,
  };

  const mainMenuOptions = [
    {
      isAccordion: false,
      isVisible: !invisibleOptions.generalOverview,
      isDisabled: isOptionsDisabled.generalOverview,
      isToggle: true,
      isNew: false,
      panelName: '',
      itemName: 'generalOverview',
      panelIdleIcon: <HomeIcon />,
      panelActiveIcon: <HomeActiveIcon />,
      panelTitle: 'Visão geral',
      action: () => history.push('/'),
    },
    {
      isAccordion: true,
      isVisible: !invisibleOptions.finances,
      isNew: false,
      panelName: 'finances',
      panelIdleIcon: <FinanceIcon />,
      panelActiveIcon: <FinanceActiveIcon />,
      panelTitle: 'Financeiro',
      accordionItems: [
        {
          isVisible: !invisibleOptions.cashIn,
          isDisabled: isOptionsDisabled.cashIn,
          isToggle: true,
          isNew: false,
          itemName: 'cashIn',
          itemTitle: 'Depósitos',
          action: () => history.push('/cash-in'),
        },
        {
          isVisible: !invisibleOptions.payment,
          isDisabled: isOptionsDisabled.payment,
          isToggle: true,
          isNew: false,
          itemName: 'payment',
          itemTitle: 'Pagamentos',
          action: () => history.push('/payroll'),
        },
        {
          isVisible: !invisibleOptions.bankExtract,
          isDisabled: isOptionsDisabled.bankExtract,
          isToggle: true,
          isNew: false,
          itemName: 'bankExtract',
          itemTitle: 'Extrato bancário',
          action: () => history.push('/extract'),
        },
        {
          isVisible: !invisibleOptions.bankExtract,
          isDisabled: isOptionsDisabled.manageAccounts,
          isToggle: true,
          isNew: false,
          itemName: 'manageAccounts',
          itemTitle: 'Gerenciar contas',
          action: () => history.push('/manage-registered-accounts'),
        },
        {
          isVisible: !invisibleOptions.pix,
          isDisabled:
            isOptionsDisabled.pix || accountSelected?.status === 'CLOSED',
          isToggle: true,
          isNew: false,
          itemName: 'pix',
          itemTitle: 'Pix',
          action: () => history.push('/cash-in/pix'),
        },

        {
          isVisible: !invisibleOptions.valuesQuery,
          isDisabled: isOptionsDisabled.valuesQuery,
          isToggle: true,
          isNew: false,
          itemName: 'valuesQuery',
          itemTitle: 'Consulta de valores',
          action: () => history.push('/consolidated-values'),
        },
        {
          isVisible: true,
          isDisabled: isOptionsDisabled.cashOut,
          isToggle: true,
          isNew: false,
          itemName: 'manageRequests',
          itemTitle: 'Gerenciar Solicitações',
          action: () => history.push('/cash-out/manage'),
        },
      ],
    },
    {
      isAccordion: true,
      isVisible: !invisibleOptions.employees,
      isNew: false,
      panelName: 'employees',
      panelIdleIcon: <EmployeesIcon />,
      panelActiveIcon: <EmployeesActiveIcon />,
      panelTitle: 'Funcionários',
      accordionItems: [
        {
          isVisible: !invisibleOptions.manageEmployees,
          isDisabled: isOptionsDisabled.manageEmployees,
          isToggle: true,
          isNew: false,
          itemName: 'manageEmployees',
          itemTitle: 'Gerenciar funcionários',
          action: () => history.push('/employees'),
        },
        // {
        //   isVisible: !invisibleOptions.manageEmployees,
        //   isDisabled: isOptionsDisabled.manageEmployees,
        //   isToggle: false,
        //   isNew: true,
        //   itemName: 'manageUsers',
        //   itemTitle: 'Gerenciar utilizadores',
        //   action: () => history.push('/employees/manage-users'),
        // },
        {
          isVisible: true,
          isDisabled: false,
          isToggle: true,
          isNew: false,
          itemName: 'registerFiles',
          itemTitle: 'Arquivos de cadastro',
          action: () => history.push('/employees/register-files'),
        },
        {
          isVisible: !invisibleOptions.paycheck,
          isDisabled: isOptionsDisabled.paycheck,
          isToggle: true,
          isNew: false,
          itemName: 'paycheck',
          itemTitle: 'Contra cheques',
          action: () => history.push('/paycheck'),
        },
        {
          isVisible: !invisibleOptions.valuesAnticipation,
          isDisabled: isOptionsDisabled.valuesAnticipation,
          isToggle: true,
          isNew: false,
          itemName: 'valuesAnticipation',
          itemTitle: 'Antecipação de valores',
          action: () => history.push('/anticipation-values'),
        },
        {
          isVisible: !invisibleOptions.limitQuery,
          isDisabled: isOptionsDisabled.limitQuery,
          isToggle: true,
          isNew: false,
          itemName: 'limitQuery',
          itemTitle: 'Consulta de limite',
          action: () => history.push('/limit-query'),
        },
      ],
    },
    {
      isAccordion: true,
      isVisible: !invisibleOptions.company,
      isNew: false,
      panelName: 'company',
      panelIdleIcon: <CompanyIcon />,
      panelActiveIcon: <CompanyActiveIcon />,
      panelTitle: 'Empresa',
      accordionItems: [
        {
          isVisible: !invisibleOptions.costCenterAndSector,
          isDisabled: isOptionsDisabled.costCenterAndSector,
          isToggle: true,
          isNew: false,
          itemName: 'costCenterAndSector',
          itemTitle: 'Centro de custo e setor',
          action: () => history.push('/cost-and-sector-center'),
        },
        {
          isVisible: !invisibleOptions.manageUsers,
          isDisabled: isOptionsDisabled.manageUsers,
          isToggle: true,
          isNew: false,
          itemName: 'manageUsers',
          itemTitle: 'Gerenciar usuários',
          action: () => {},
        },
        {
          isVisible: !invisibleOptions.myPlanSomapay,
          isDisabled: isOptionsDisabled.myPlanSomapay,
          isToggle: true,
          isNew: false,
          itemName: 'myPlanSomapay',
          itemTitle: 'Meu plano Somapay',
          action: () => history.push('/my-plan'),
        },
        {
          isVisible: !invisibleOptions.somapayAcademy,
          isDisabled: isOptionsDisabled.somapayAcademy,
          isToggle: false,
          isNew: false,
          itemName: 'somapayAcademy',
          itemTitle: 'Somapay academy',
          action: () => window.open(SOMAPAY_ACADEMY_LINK, '_blank').focus(),
        },
        {
          isVisible: !invisibleOptions.accessSettings,
          isDisabled: isOptionsDisabled.accessSettings,
          isToggle: false,
          isNew: false,
          itemName: 'accessSettings',
          itemTitle: 'Configuração de acesso',
          action: () => history.push('/access-settings'),
        },
      ],
    },
    {
      isAccordion: true,
      isNew: false,
      isVisible: true,
      panelName: 'onlinePoint',
      panelIdleIcon: <ClockIcon />,
      panelActiveIcon: <ClockActiveIcon />,
      panelTitle: 'Ponto online',
      accordionItems: [
        {
          isVisible: true,
          isDisabled: false,
          isToggle: true,
          isNew: false,
          itemName: 'collaborators',
          itemTitle: 'Colaboradores',
          action: () => history.push('/online-point-employees'),
        },
        {
          isVisible: !invisibleOptions.myPoint,
          isDisabled: isOptionsDisabled.myPoint,
          isToggle: true,
          isNew: false,
          itemName: 'myPoint',
          itemTitle: 'Meu ponto',
          action: () => history.push('/online-point-plan'),
        },
        {
          isVisible: !invisibleOptions.invoices,
          isDisabled: isOptionsDisabled.invoices,
          isToggle: true,
          isNew: false,
          itemName: 'invoices',
          itemTitle: 'Faturas',
          action: () => history.push('/online-point-charges'),
        },
      ],
    },
    {
      isAccordion: true,
      isVisible: !invisibleOptions.consigned,
      isNew: false,
      panelName: 'consigned',
      panelIdleIcon: <ConsignedIcon />,
      panelActiveIcon: <ConsignedActiveIcon />,
      panelTitle: 'Consignado',
      accordionItems: [
        {
          isVisible: !invisibleOptions.consigned,
          isDisabled: isOptionsDisabled.consigned,
          isToggle: true,
          isNew: false,
          itemName: 'consignedProposes',
          itemTitle: 'Propostas',
          action: () => history.push('/consigned/proposes'),
        },
        // {
        //   isVisible: !invisibleOptions.consigned,
        //   isDisabled: isOptionsDisabled.consigned,
        //   isToggle: true,
        //   isNew: true,
        //   itemName: 'consignedMargin',
        //   itemTitle: 'Margens',
        //   action: () => history.push('/consigned/margins'),
        // },
      ],
    },
    {
      isAccordion: true,
      isVisible: !invisibleOptions.beevaleBenefits,
      isNew: false,
      panelName: 'beevaleBenefits',
      panelIdleIcon: <BeeValeIcon />,
      panelActiveIcon: <BeeValeActiveIcon />,
      panelTitle: 'Benefícios BeeVale',
      accordionItems: [
        {
          isVisible: !invisibleOptions.beevaleBenefits,
          isDisabled: isOptionsDisabled.beevaleBenefits,
          isToggle: true,
          isNew: false,
          itemName: 'beevaleBenefitsEmployee',
          itemTitle: 'Colaboradores',
          action: () => history.push('/beevaleBenefits/employee'),
        },
        {
          isVisible: !invisibleOptions.beevaleBenefits,
          isDisabled: isOptionsDisabled.beevaleBenefits,
          isToggle: true,
          isNew: false,
          itemName: 'beevaleBenefitsRecharge',
          itemTitle: 'Recargas',
          action: () => history.push('/beevaleBenefits/recharge'),
        },
      ],
    },
    {
      isAccordion: true,
      isVisible: !invisibleOptions.insurance,
      isNew: false,
      panelName: 'insurance',
      panelIdleIcon: <InsuranceIcon />,
      panelActiveIcon: <InsuranceActiveIcon />,
      panelTitle: 'Seguros',
      accordionItems: [
        {
          isVisible: !invisibleOptions.insurance,
          isDisabled: isOptionsDisabled.insurance,
          isToggle: true,
          isNew: false,
          itemName: 'insurance',
          itemTitle: 'Gerenciar seguros',
          action: () => history.push('/insurance-management'),
        },
        {
          isVisible: !invisibleOptions.internInsurance,
          isDisabled: isOptionsDisabled.internInsurance,
          isToggle: false,
          isNew: false,
          itemName: 'internInsurance',
          itemTitle: 'Seguro estagiário',
          action: () => window.open(INTERN_INSURANCE_LINK, '_blank').focus(),
        },
        {
          isVisible: !invisibleOptions.lifeInsurance,
          isDisabled: isOptionsDisabled.lifeInsurance,
          isToggle: false,
          isNew: false,
          itemName: 'lifeInsurance',
          itemTitle: 'Seguro de vida',
          action: () => window.open(LIFE_INSURANCE_LINK, '_blank').focus(),
        },
        {
          isVisible: !invisibleOptions.dentalInsurance,
          isDisabled: isOptionsDisabled.dentalInsurance,
          isToggle: false,
          isNew: false,
          itemName: 'dentalInsurance',
          itemTitle: 'Seguro odontológico',
          action: () => window.open(DENTAL_INSURANCE_LINK, '_blank').focus(),
        },
      ],
    },
    {
      isAccordion: false,
      isVisible: !invisibleOptions.supportService,
      isDisabled: isOptionsDisabled.supportService,
      isToggle: false,
      isNew: false,
      panelName: '',
      itemName: 'supportService',
      panelIdleIcon: <CustomerSupportIcon />,
      panelTitle: 'Atendimento online',
      action: () => {
        if (process.env.REACT_APP_BRAND_FOR_LOGIN_VALIDATION === 'FORTESPAY') {
          window.open(ONLINE_SUPPORT_LINK_FORTESPAY, '_blank').focus();
        } else {
          window.open(ONLINE_SUPPORT_LINK_GENERAL, '_blank').focus();
        }
      },
    },
    {
      isAccordion: false,
      isVisible: !invisibleOptions.helpCenter,
      isDisabled: isOptionsDisabled.helpCenter,
      isToggle: true,
      isNew: false,
      panelName: 'helpCenter',
      itemName: 'helpCenter',
      panelIdleIcon: <HelpIcon />,
      panelActiveIcon: <HelpActiveIcon />,
      panelTitle: 'Conta com a gente',
      action: () => history.push('/help-center'),
    },
    {
      isAccordion: false,
      isVisible: !invisibleOptions.exit,
      isDisabled: false,
      isToggle: false,
      isNew: false,
      panelName: '',
      itemName: 'exit',
      panelIdleIcon: <ExitFromPlatform />,
      panelTitle: 'Sair',
      action: onConfirmExit,
    },
  ];

  return { mainMenuOptions };
}
