import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { hasAuthorization, profiles } from '../../utils/hasAuthorization';

const useStyles = makeStyles(theme => ({
  root: {
    whiteSpace: 'nowrap',
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  staticTooltipLabel: {
    display: 'inline',
    fontSize: 12,
    color: '#A41E1D',
    textAlign: 'right',
  },
  backdrop: {
    zIndex: 1000,
    color: '#FAFAFA',
    minHeight: '100vh',
    height: '100%',
  },
}));

export default function FloatButtonDownloadArchives({
  actions,
  hidden,
  csvDownload,
  pdfDownload,
  xlsDownload,
  pdfDownloadExtract,
  csvDownloadExtract,
  renderButton,
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const companies = JSON.parse(sessionStorage.getItem('companies'));
  const currentCompanyIndex = sessionStorage.getItem('currentCompanyIndex');
  const { payrollProduct } = companies[currentCompanyIndex || 0];
  const ACCESS_PROFILES = JSON.parse(sessionStorage.getItem('accessProfile'));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function menuValidate(value) {
    if (
      (value === 'FILE_PDF' || value === 'FILE_CSV' || value === 'FILE_XLS') &&
      renderButton === true &&
      hasAuthorization(ACCESS_PROFILES, [
        profiles.ADMIN,
        profiles.PERSONAL_DEPARTMENT,
        profiles.FINANCIAL,
      ]) &&
      payrollProduct
    ) {
      return true;
    }

    if (value === '') {
      return true;
    }

    return false;
  }

  const onClick = name => {
    switch (name) {
      case 'PDF_EXTRACT':
        return pdfDownloadExtract();
      case 'CSV_EXTRACT':
        return csvDownloadExtract();
      case 'CSV':
        return csvDownload();
      case 'PDF':
        return pdfDownload();
      case 'XLS_EXTRACT':
        return xlsDownload();
    }
  };

  return (
    <div className={classes.root} style={hidden ? { display: 'none' } : {}}>
      <Backdrop
        open={open}
        className={classes.backdrop}
        onClick={handleClose}
      />
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions?.map(action => {
          const validate = menuValidate(action.value);

          return (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              tooltipOpen
              classes={classes}
              style={validate ? {} : { display: 'none' }}
              onClick={() => onClick(action.onClick, action.name)}
            />
          );
        })}
      </SpeedDial>
    </div>
  );
}
