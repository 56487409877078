import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { unwrapResult } from '@reduxjs/toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  IconButton,
  Typography,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import Form from '../../../components/Form';

import { ResetPasswordActions as Actions } from '../../../redux/store/ResetPassword';

import { handleSnackbarStack } from '../../../utils/handleSnackbarStack';
import useGeolocation from '../../../hooks/useGeolocation';

import { ReactComponent as LogoEmpresa } from '../../../assets/Logo_Branco.svg';
import { ReactComponent as ArrowBack } from '../../../assets/arrow_back.svg';

import styles from './styles.module.css';

const validations = yup.object().shape({
  emailOrCpf: yup
    .string()
    .email()
    .required(),
});

const EmailAndCpf = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useGeolocation();
  const { error } = handleSnackbarStack();

  const { isLoading } = useSelector(state => state.resetPassword);

  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: 'onChange',
    initialValues: {
      emailOrCpf: '',
    },
    resolver: yupResolver(validations),
  });

  const sendEmailOrCpf = async ({ emailOrCpf }) => {
    const actionResult = await dispatch(Actions.getEmailToken({ emailOrCpf }));

    try {
      unwrapResult(actionResult);
      goToNextStep();
    } catch (err) {
      error(err);
    }
  };

  const goToNextStep = () => {
    history.push('/reset-password/validate-token');
  };

  const goBackStep = () => {
    history.push('/login');
  };

  useEffect(() => {
    dispatch(Actions.setLocation(location));
  }, [location]);

  return (
    <div className={styles.pageContainer}>
      <MuiThemeProvider>
        <Container>
          <CssBaseline />
          <div className={styles.paper}>
            <IconButton className={styles.arrowBack} onClick={goBackStep}>
              <ArrowBack />
            </IconButton>
            <div>
              <LogoEmpresa width={150} height="fitContent" />
            </div>
            <form
              className={styles.form}
              onSubmit={handleSubmit(sendEmailOrCpf)}
            >
              <div className={styles.content}>
                <Typography className={styles.textBigger}>
                  Recuperar Acesso
                </Typography>
                <Typography className={styles.textSmall}>
                  Enviaremos um código para o e-mail associado ao seu acesso.
                  Você precisará deste código para as próximas etapas.
                </Typography>
                <Controller
                  control={control}
                  name="emailOrCpf"
                  render={({ field }) => (
                    <Form.TextField
                      size="medium"
                      variant="outlined"
                      margin="normal"
                      error={!!errors?.emailOrCpf}
                      helperText={errors?.emailOrCpf?.message}
                      fullWidth
                      label="Informe o e-mail"
                      autoComplete="email"
                      className={styles.input}
                      inputProps={{
                        inputMode: 'email',
                      }}
                      {...field}
                      disabled={isLoading}
                    />
                  )}
                />
              </div>
              <div className={styles.submitArea}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={styles.submit}
                  type="submit"
                  disabled={!isValid}
                >
                  {isLoading ? (
                    <div className={styles.circularProgress}>
                      <CircularProgress
                        className={styles.spinner}
                        size={styles.spinner}
                      />
                    </div>
                  ) : (
                    'Enviar'
                  )}
                </Button>
              </div>
            </form>
          </div>
        </Container>
      </MuiThemeProvider>
    </div>
  );
};

export default EmailAndCpf;
