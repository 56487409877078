/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-param-reassign */
/* eslint-disable no-useless-escape */
import React from 'react';
import { Form, Field } from 'react-final-form';

import { Button, InputAdornment, IconButton } from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {
  Box,
  CircularProgress,
  Typography,
  TextField,
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { ReactComponent as Logo } from '../../../assets/Logo_Branco.svg';

import styles from './styles.module.css';
import { isMobile } from '../../../utils/breakpoints';
import { ValidateFormSchema } from '../../../utils/validateFormSchema';
import Indicator from './Indicator';

import validations from './validations';

export default function FormPassword({
  goToBack,
  createPassword,
  handleShowPassword,
  handleShowSecondPassword,
  showPassword,
  showSecondPassword,
  loading,
}) {
  return (
    <div className={styles.form}>
      <MuiThemeProvider>
        <IconButton className={styles.arrowBack} onClick={goToBack}>
          <ArrowBackRoundedIcon />
        </IconButton>

        <Box mb={3}>
          <Logo className={styles.logoCompany} />
        </Box>

        <Box mb={3}>
          <Typography className={styles.biggerText}>Crie uma senha:</Typography>
        </Box>
        <Form
          initialValues={{ password: '', repeatPassword: '' }}
          validate={ValidateFormSchema(validations)}
          onSubmit={createPassword}
        >
          {props => (
            <form
              onSubmit={props.handleSubmit}
              className={styles.formContainer}
            >
              <Box mb={3}>
                <Field name="password">
                  {({ input, meta }) => (
                    <TextField
                      fullWidth
                      label={
                        isMobile
                          ? 'Digite 8 caracteres'
                          : 'Digite uma senha de 8 caracteres'
                      }
                      value={input.value}
                      onChange={input.onChange}
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      error={!!meta.submitFailed && meta.error}
                      helperText={meta.submitFailed && meta.error}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowPassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Field>
              </Box>

              <Box mb={3}>
                <Field name="repeatPassword">
                  {({ input, meta }) => (
                    <TextField
                      fullWidth
                      label={
                        isMobile
                          ? 'Digite novamente'
                          : 'Digite novamente a senha'
                      }
                      value={input.value}
                      onChange={input.onChange}
                      variant="outlined"
                      type={showSecondPassword ? 'text' : 'password'}
                      error={!!meta.submitFailed && meta.error}
                      helperText={meta.submitFailed && meta.error}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleShowSecondPassword}>
                              {showSecondPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </Field>
              </Box>
              <div className={styles.validationPoints}>
                <Field name="length">
                  {({ input, meta }) => (
                    <Indicator error={meta.error || !meta.dirty}>
                      {input.onChange(props.values.password)}
                      No mínimo 8 caracteres
                    </Indicator>
                  )}
                </Field>
                <Field name="special">
                  {({ input, meta }) => (
                    <Indicator error={meta.error || !meta.dirty}>
                      {input.onChange(props.values.password)}
                      Pelo menos 1 caractere especial
                    </Indicator>
                  )}
                </Field>
                <Field name="uppercase">
                  {({ input, meta }) => (
                    <Indicator error={meta.error || !meta.dirty}>
                      {input.onChange(props.values.password)}
                      Pelo menos 1 letra maiúscula
                    </Indicator>
                  )}
                </Field>
                <Field name="lowercase">
                  {({ input, meta }) => (
                    <Indicator error={meta.error || !meta.dirty}>
                      {input.onChange(props.values.password)}
                      Pelo menos 1 letra minúscula
                    </Indicator>
                  )}
                </Field>
                <Field name="number">
                  {({ input, meta }) => (
                    <Indicator error={meta.error || !meta.dirty}>
                      {input.onChange(props.values.password)}
                      Pelo menos 1 número
                    </Indicator>
                  )}
                </Field>
              </div>

              <Box className={styles.submitArea}>
                <Button
                  type="submit"
                  color="primary"
                  disabled={
                    props.pristine || props.hasValidationErrors || loading
                  }
                  className={styles.submit}
                >
                  {loading ? (
                    <CircularProgress className={styles.circularProgress} />
                  ) : (
                    'Criar senha'
                  )}
                </Button>
              </Box>
            </form>
          )}
        </Form>
      </MuiThemeProvider>
    </div>
  );
}
