import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  Button,
  CircularProgress,
  IconButton,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';

import styles from './styles.module.css';
import { isMobile } from '../../../../../utils/breakpoints';

import StatusColor from './StatusColor';
import Drawer from './Drawer';
import PurpleCheckbox from '../../../../../components/Checkbox/PurpleCheckbox';
import FakeList from '../../../../../components/FakeList';
import ResultNotFound from '../../../../../components/ResultNotFound';
import PurpleRadio from '../../../../../components/RadioButton/PurpleRadio';

export default function InsurancesList({
  employeeData,
  selected,
  setSelected,
  handleContinue,
  openDrawer,
  setOpenDrawer,
  loadingTable,
  rowsPerPage,
  setRowsPerPage,
  currentPageSize
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  const insuranceSituationStorage = sessionStorage.getItem('insuranceSituation');
  const insuranceTypeStorage = sessionStorage.getItem('insuranceType');

  const [insuranceType, setInsuranceType] = useState(
    insuranceTypeStorage ? insuranceTypeStorage : 'Seguro de Vida'
  );
  const [insuranceSituation, setInsuranceSituation] = useState(
    insuranceSituationStorage ? insuranceSituationStorage : 'Vigente'
  );

  const [fetchMoreData, setFetchMoreData] = useState(false);

  function handleClick(event, id, item) {
    const selectedIndex = selected.map(function(e) { return e.id; }).indexOf(item.id);
    let newSelected = [];

    if (selectedIndex === -1 && item.situation !== "Em cancelamento") {
      newSelected = newSelected.concat(selected, {
        id: item.id,
        cpf: item.cpf,
        name: item.name,
        initialValidityDate: item.initialValidityDate,
        insuranceValue: item.insuranceValue,
        companyEncodedKey: item.insuranceValue,
        type: item.type,
        situation: item.situation,
        cancellationDate: item.cancellationDate,
        createdDate: item.createdDate,
        creationUser: item.creationUser,
      });
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    if (item.situation !== "Em cancelamento") {
      sessionStorage.setItem('insuranceEmployeesTable', JSON.stringify(newSelected));
      setSelected(newSelected);
    }
  };

  const isSelected = (row) => selected.map(function(e) {
    return e?.id;
  }).indexOf(row?.id) !== -1;

  function goToDetails(item) {
    sessionStorage.setItem('useTopBar', JSON.stringify({
      name: 'Dados do segurado',
      step: 0,
      route: history.location.pathname
    }));

    history.push(`/insurance-management/insurance-details/${item.cpf}`)
  }

  // to do: reativar quando forem incluidos outros tipos de seguros
  // function onChangeInsuranceType(event) {
  //   setInsuranceType(event.target.value);
  // }

  function onChangeInsuranceSituation(event) {
    setInsuranceSituation(event.target.value);
  }

  function handleFilter() {
    dispatch({
      type: 'SET_INSURANCE_TYPE',
      insuranceType
    });

    dispatch({
      type: 'SET_INSURANCE_SITUATION',
      insuranceSituation
    });

    sessionStorage.setItem('insuranceType', insuranceType);
    sessionStorage.setItem('insuranceSituation', insuranceSituation);

    setOpenDrawer(false);
  }

  function fetchData() {
    setRowsPerPage(rowsPerPage + 10);
    setFetchMoreData(true);
  }

  function renderContentDrawer() {
    return (
      <div className={styles.drawerContainer}>
        <div>
          <Typography
              className={styles.drawerTitle}
            >
              Filtrar
            </Typography>
          {/*
            <FormControl variant="outlined" className={styles.inputType}>
              <InputLabel id="outlined-type" className={styles.labelType}>
                Tipo
              </InputLabel>
              <Select
                labelId="outlined-type"
                id="outlined-type"
                value={insuranceType}
                onChange={onChangeInsuranceType}
                label="Tipo"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                fullWidth
              >
                <MenuItem value="Seguro de Vida">Vida</MenuItem>
                <MenuItem value="Seguro Saúde">Saúde</MenuItem>
                <MenuItem value="Seguro Odontológico">Odontológico</MenuItem>
              </Select>
            </FormControl> */}

            <Typography className={styles.drawerLabel}>
              Situação
            </Typography>

            <div className={styles.radioContainer}>
              <FormControlLabel
                value="Vigente"
                control={
                  <PurpleRadio
                    checked={insuranceSituation === 'Vigente'}
                    onChange={onChangeInsuranceSituation}
                    value="Vigente"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'Vigente' }}
                    color="#A41E1D"
                  />
                }
                label="Vigentes"
              />
              <FormControlLabel
                value="Cancelado"
                control={
                  <PurpleRadio
                    checked={insuranceSituation === 'Cancelado'}
                    onChange={onChangeInsuranceSituation}
                    value="Cancelado"
                    name="radio-button-demo"
                    inputProps={{ 'aria-label': 'Cancelado' }}
                  />
                }
                label="Cancelados"
              />
            </div>
        </div>

        <Button
          className={styles.drawerButton}
          onClick={handleFilter}
        >
          Aplicar filtros
        </Button>
      </div>
    )
  }

  if (employeeData.length === 0 && isMobile) {
    if (loadingTable) {
      return <FakeList />
    }

    return (
      <>
        <ResultNotFound
          text="Nenhum Resultado Encontrado"
        />
        <Drawer
          openDrawer={openDrawer}
          onClose={() => setOpenDrawer(false)}
          anchor="right"
          content={renderContentDrawer()}
        />
      </>
    )
  }

  return (
    <div
      className={styles.insuranceListContainer}
      style={selected.length > 0 ? { marginBottom: '80px' } : {}}
    >
      <InfiniteScroll
        dataLength={employeeData?.length}
        next={fetchData}
        hasMore={true}
        loader={
          (fetchData && (currentPageSize < (rowsPerPage - 5))) ? (
          <div className={styles.circularProgressContainer}>
            <CircularProgress size={25} className={styles.circularProgress} />
          </div>
        ) : ''}
      >
        {employeeData.map((item, index) => {
          const isItemSelected = isSelected(item);
          const labelId = `enhanced-table-checkbox-${index}`;

          if (loadingTable && !fetchMoreData) {
            return <FakeList />
          }

          return (
            <div className={styles.card}>
              <div className={styles.cardData}>
                <FormControlLabel
                  control={
                    <PurpleCheckbox
                      onChange={(event) => handleClick(event, item.id, item)}
                      checked={isItemSelected}
                      inputProps={{ 'aria-labelledby': labelId }}
                      disabled={item.situation === "Em cancelamento"}
                      className={styles.checkbox}
                    />
                  }
                />

                <div>
                  <p className={styles.cardName}>
                    {item.name}
                  </p>
                  <span className={styles.cardCpf}>
                    {item.cpf}
                  </span>

                  <div>
                    <StatusColor status={item.situation} />
                  </div>
                </div>
              </div>

              <IconButton
                className={styles.iconButton}
                onClick={() => goToDetails(item)}
              >
                <ArrowForwardIosRoundedIcon className={styles.arrowForward} />
              </IconButton>
            </div>
          )
        })}
      </InfiniteScroll>

      <div className={selected.length === 0 ? styles.hidden : styles.footer}>
        {selected?.length > 0 && (
          <span className={selected.length === 0 ? styles.hidden : styles.count}>
            <span className={styles.countNumber}>
              {selected?.length}
            </span>
            {' '}
            Selecionados
          </span>
        )}

        <Button
          className={styles.continueButton}
          onClick={handleContinue}
        >
          {insuranceSituation === 'Vigente'
            ? 'Cancelar seguros'
            : 'Ativar seguros'}
        </Button>
      </div>

      <Drawer
        openDrawer={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="right"
        content={renderContentDrawer()}
      />
    </div>
  )
}
