import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

import {
  Typography,
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Box,
  Hidden,
  Drawer,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { Search } from '@material-ui/icons';
import { styles, useStyles, theme } from './styles';
import style from './styles.module.css';
import { useStyles1 } from './UI/styles';
import Filters from './UI/Filters/Filters';
import ContentFilterDrawer from './UI/ContentFilterDrawer';
import Table from './UI/Table';
import LogoLoading from '../../../components/LogoLoading';
import LoadingModal from '../../../components/LoadingModal';
import ResultNotFound from '../../../components/ResultNotFound';
import AnticipationMobileList from '../../../components/AnticipationMobileList';
import { useValidationForUnformattedDate } from '../../../hooks/useValidationForUnformattedDate';
import { api } from '../../../services/api';
import convertBytesToBlobDowload from '../../../utils/convertBytesToBlobDowload';
import { ReactComponent as DollarSign } from '../../../assets/DollarSign.svg';
import { ReactComponent as FilterIcon } from '../../../assets/filter-icon.svg';
import FloatButtonDownloadArchives from '../../../components/FloatButtonDownloadArchives';
import { actions } from './actions';
import verifyNumberInString from '../../../utils/verifyNumberInString';
import convertBytesToBlobDowloadCsv from '../../../utils/convertBytesToBlobDownloadCsv';
import convertBytesToBlobDowloadXls from '../../../utils/convertBytesToBlobDownloadXls';
import { pusherChannels, pusherStatus } from '../../../enums/pusher';

import { pusher } from '../../../utils/pusher';

const isMobile = window.innerWidth <= 500 || window.innerHeight <= 500;

const ConsultAnticipation = () => {
  const [anticipations, setAnticipations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [reportId, setReportId] = useState({});
  const anchorRef = useRef(null);
  const companyCodeValueReducer = useSelector(
    state => state.currentCompanyReducer.company,
  );
  const companyCodeValueStorage = sessionStorage.getItem('currentCompanyCode');
  const companyCodeValue = companyCodeValueStorage || companyCodeValueReducer;
  const classes = useStyles();
  const classes1 = useStyles1();
  const todayDate = moment().format('YYYY-MM-DD');
  const initialDateReduc = moment(todayDate).subtract(1, 'month');
  const initialDateRecucForm = moment(initialDateReduc).format('YYYY-MM-DD');
  const [searchText, setSearchText] = useState('');
  const [finalDate, setFinalDate] = useState(todayDate || '');
  const [initialDate, setInitialDate] = useState(initialDateRecucForm);
  const [changedFilter, setChangedFilter] = useState(false);
  const [cleanFilter, setCleanFilter] = useState(false);
  const [state, setState] = useState({
    right: false,
  });
  const [searchFocus, setSearchFocus] = useState('');
  const [loadingList, setLoadingList] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const validateInitialDateInfo = useValidationForUnformattedDate(initialDate);
  const validateFinalDateInfo = useValidationForUnformattedDate(finalDate);
  const [loadingDownload, setLoadingDownload] = useState(false);
  const [loadingDownloadCsv, setLoadingDownloadCsv] = useState(false);
  const [loadingDownloadXls, setLoadingDownloadXls] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const { SALARY_ANTICIPATION_REPORT_FINISHED } = pusherChannels;
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (searchText || initialDate || finalDate) {
      setCleanFilter(false);
      setChangedFilter(true);
    }
  }, [searchText, initialDate, finalDate]);

  useEffect(() => {
    if (changedFilter) {
      const delay = setTimeout(() => {
        anticipationList();
      }, 500);

      return () => clearTimeout(delay);
    }
  }, [searchText]);

  useEffect(() => {}, [percentage]);

  useEffect(() => {
    if (initialDate && finalDate) {
      setCurrentPage(0);
      anticipationList();
    }
  }, [initialDate, finalDate]);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  function handleClearFilters() {
    setInitialDate('');
    setFinalDate('');
    setSearchText('');
    setCleanFilter(true);
    setLoadingList(true);
  }

  const anticipationList = () => {
    const IS_NUMBER = verifyNumberInString(searchText);

    setLoadingList(true);
    setLoadingFilter(true);

    const requestOptions = {
      headers: {
        accept: 'application/json',
      },
      params: {
        cpf: IS_NUMBER && searchText ? searchText : null,
        name: !IS_NUMBER && searchText ? searchText : null,
        startDate: initialDate && finalDate ? initialDate : '',
        endDate: initialDate && finalDate ? finalDate : '',
      },
    };

    api
      .get(`/anticipation/report/${companyCodeValue}`, requestOptions)
      .then(({ data }) => {
        const dataResult = data;
        setAnticipations(dataResult);
      })
      .finally(() => {
        setLoadingFilter(false);
        setLoading(false);
        setLoadingList(false);
        setFetchData(false);
      });
  };

  async function csvDownload() {
    setLoadingDownloadCsv(true);

    const IS_NUMBER = verifyNumberInString(searchText);

    const requestOptions = {
      headers: {
        accept: 'text/csv',
      },
      params: {
        startDate: initialDate && finalDate ? initialDate : '',
        endDate: initialDate && finalDate ? finalDate : '',
        cpf: IS_NUMBER && searchText ? searchText : null,
        name: !IS_NUMBER && searchText ? searchText : null,
      },
      responseType: 'blob',
    };

    const sendRequest = new Promise((resolve, reject) => {
      api
        .get(`/anticipation/report/${companyCodeValue}/async`, requestOptions)
        .then(getJson => {
          setPercentage(10);
          resolve(getJson.data);
        });
    });

    const processRequest = new Promise((resolve, reject) => {
      sendRequest.then(data => {
        const channel = pusher.subscribe(companyCodeValue);
        channel.bind('SALARY_ANTICIPATION_REPORT_FINISHED', async function(
          data,
        ) {
          setPercentage(27);
          setPercentage(57);
          resolve(data);
        });
      });
    });

    const doneRequest = new Promise((resolve, reject) => {
      processRequest.then(testeid => {
        const requestOptionsAsync = {
          headers: {
            accept: 'text/csv',
          },
          responseType: 'blob',
        };
        setPercentage(78);

        setTimeout(() => {
          setPercentage(87);
        }, 3000);

        api
          .get(
            `/anticipation/report/byId/${testeid?.data?.reportId}`,
            requestOptionsAsync,
          )
          .then(({ data }) => {
            setPercentage(99);
            resolve(data);
          });
      });
    });
    Promise.all([sendRequest, processRequest, doneRequest]).then(
      resultPromise => {
        setPercentage(100);
        const nameDocument = 'documento';
        convertBytesToBlobDowloadCsv(resultPromise[2], nameDocument);
        setLoadingDownloadCsv(false);
      },
    );
    // setLoadingDownloadCsv(true);

    // const IS_NUMBER = verifyNumberInString(searchText);

    // const requestOptions = {
    //   headers: {
    //     accept: 'text/csv',
    //   },
    //   params: {
    //     startDate: initialDate && finalDate ? initialDate : '',
    //     endDate: initialDate && finalDate ? finalDate : '',
    //     cpf: IS_NUMBER && searchText ? searchText : null,
    //     name: !IS_NUMBER && searchText ? searchText : null,
    //   },
    // };
    // api
    //   .get(`/anticipation/report/${companyCodeValue}`, requestOptions)
    //   .then(({ data }) => {
    //     const nameDocument = 'documento';
    //     convertBytesToBlobDowloadCsv(data, nameDocument);
    //     setLoadingDownloadCsv(false);
    //   });
  }

  async function xlsDownload() {
    setLoadingDownloadXls(true);

    const IS_NUMBER = verifyNumberInString(searchText);

    const requestOptions = {
      headers: {
        accept: 'application/vnd.ms-excel',
      },
      params: {
        startDate: initialDate && finalDate ? initialDate : '',
        endDate: initialDate && finalDate ? finalDate : '',
        cpf: IS_NUMBER && searchText ? searchText : null,
        name: !IS_NUMBER && searchText ? searchText : null,
      },
      responseType: 'blob',
    };

    const sendRequest = new Promise((resolve, reject) => {
      api
        .get(`/anticipation/report/${companyCodeValue}/async`, requestOptions)
        .then(getJson => {
          setPercentage(10);
          resolve(getJson.data);
        });
    });
    setTimeout(() => {
      setPercentage(19);
    }, 2000);

    const processRequest = new Promise((resolve, reject) => {
      sendRequest.then(data => {
        const channel = pusher.subscribe(companyCodeValue);
        channel.bind('SALARY_ANTICIPATION_REPORT_FINISHED', async function(
          data,
        ) {
          setPercentage(27);
          setPercentage(57);
          resolve(data);
        });
      });
    });

    const doneRequest = new Promise((resolve, reject) => {
      processRequest.then(testeid => {
        const requestOptionsAsync = {
          headers: {
            accept: 'application/vnd.ms-excel',
          },
          responseType: 'blob',
        };
        setPercentage(78);

        setTimeout(() => {
          setPercentage(87);
        }, 3000);

        api
          .get(
            `/anticipation/report/byId/${testeid?.data?.reportId}`,
            requestOptionsAsync,
          )
          .then(({ data }) => {
            setPercentage(99);
            resolve(data);
          });
      });
    });
    Promise.all([sendRequest, processRequest, doneRequest]).then(
      resultPromise => {
        setPercentage(100);
        const nameDocument = 'documento';
        convertBytesToBlobDowloadXls(resultPromise[2], nameDocument);
        setLoadingDownloadXls(false);
      },
    );
  }

  async function pdfDownload() {
    setLoadingDownload(true);

    const IS_NUMBER = verifyNumberInString(searchText);

    const requestOptions = {
      headers: {
        accept: 'application/pdf',
      },
      params: {
        startDate: initialDate && finalDate ? initialDate : '',
        endDate: initialDate && finalDate ? finalDate : '',
        cpf: IS_NUMBER && searchText ? searchText : null,
        name: !IS_NUMBER && searchText ? searchText : null,
      },
      responseType: 'blob',
    };

    const sendRequest = new Promise((resolve, reject) => {
      api
        .get(`/anticipation/report/${companyCodeValue}/async`, requestOptions)
        .then(getJson => {
          setPercentage(10);
          resolve(getJson.data);
        });
    });
    setTimeout(() => {
      setPercentage(19);
    }, 2000);

    const processRequest = new Promise((resolve, reject) => {
      sendRequest.then(data => {
        const channel = pusher.subscribe(companyCodeValue);
        channel.bind('SALARY_ANTICIPATION_REPORT_FINISHED', async function(
          data,
        ) {
          setPercentage(27);
          setPercentage(57);
          resolve(data);
        });
      });
    });

    const doneRequest = new Promise((resolve, reject) => {
      processRequest.then(testeid => {
        const requestOptionsAsync = {
          headers: {
            accept: 'application/pdf',
          },
          responseType: 'blob',
        };
        setPercentage(78);

        setTimeout(() => {
          setPercentage(87);
        }, 3000);

        api
          .get(
            `/anticipation/report/byId/${testeid?.data?.reportId}`,
            requestOptionsAsync,
          )
          .then(({ data }) => {
            setPercentage(99);
            resolve(data);
          });
      });
    });
    Promise.all([sendRequest, processRequest, doneRequest]).then(
      resultPromise => {
        setPercentage(100);
        const nameDocument = 'documento';
        convertBytesToBlobDowload(resultPromise[2], nameDocument);
        setLoadingDownload(false);
      },
    );

    // api
    //   .get(`/anticipation/report/${companyCodeValue}`, requestOptions)
    //   .then(({ data }) => {
    //     const nameDocument = 'documento';
    //     convertBytesToBlobDowload(data, nameDocument);
    //     setLoadingDownload(false);
    //   });
  }

  const onSearchChangeFilters = event => {
    setCurrentPage(0);
    setSearchText(event.target.value);
  };

  const onSearchTextChange = event => {
    setSearchText(event.target.value);
  };

  const onChangeInitialDate = value => {
    setInitialDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : value,
    );
  };

  const onChangeFinalDate = value => {
    setFinalDate(
      moment(value).format('YYYY-MM-DD') !== 'Data inválida'
        ? moment(value).format('YYYY-MM-DD')
        : value,
    );
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const toggleDrawer = (anchor, open) => event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  function renderMobileList() {
    return (
      <AnticipationMobileList
        data={anticipations}
        loadingList={loadingList}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isMobile={isMobile}
        fetchData={fetchData}
      />
    );
  }

  function renderAnticipationFilter() {
    return (
      <TextField
        id="outlined-number"
        type="number"
        onFocus={() => setSearchFocus(true)}
        onBlur={() => setSearchFocus(false)}
        className={searchFocus ? style.nsuFocus : style.nsu}
        onChange={onSearchTextChange}
        value={searchText}
        placeholder="Digite o nome ou cpf"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search className={style.searchIcon} />
            </InputAdornment>
          ),
          inputMode: 'numeric',
        }}
      />
    );
  }

  const renderFilters = () => {
    return (
      <Filters
        searchText={searchText}
        initialDate={initialDate}
        finalDate={finalDate}
        handleClearFilters={() => handleClearFilters()}
        onSearchChangeFilters={onSearchChangeFilters}
        onChangeInitialDate={onChangeInitialDate}
        onChangeFinalDate={onChangeFinalDate}
        validateInitialDateInfo={validateInitialDateInfo}
        validateFinalDateInfo={validateFinalDateInfo}
        changedFilter={changedFilter}
      />
    );
  };

  const renderTableOrList = () => {
    return (
      <>
        {anticipations.length === 0 && changedFilter ? (
          <>
            {loadingFilter && !isMobile ? (
              <>
                {renderFilters()}
                <Table
                  loadingFilter={loadingFilter}
                  theme={theme}
                  classesHead={classes1}
                  classes={classes}
                  loading={loading}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  anticipationList={anticipations}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </>
            ) : (
              <>
                {renderFilters()}

                <ResultNotFound
                  text="Nenhum Resultado Encontrado"
                  alternativeText="Verifique os dados pesquisados e tente novamente."
                  buttonText="Limpar Filtros"
                  onClick={handleClearFilters}
                />
              </>
            )}
          </>
        ) : anticipations.length === 0 ? (
          renderAnticipationView()
        ) : (
          <>
            {renderFilters()}
            <Table
              loadingFilter={loadingFilter}
              theme={theme}
              classesHead={classes1}
              classes={classes}
              loading={loading}
              pageSize={pageSize}
              currentPage={currentPage}
              anticipationList={anticipations}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </>
        )}
      </>
    );
  };

  const renderAnticipationView = () => {
    return (
      <div>
        <div
          className={isMobile ? styles.mobileContentStyle : styles.contentStyle}
        >
          <ResultNotFound
            text="Antecipação de Valores."
            alternativeText="Todas as informações da antecipação de valores aparecerão nesta página."
            onClick={handleClick}
            icon={<DollarSign />}
          />
        </div>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <Grid
        container
        direction="row"
        wrap="nowrap"
        justify="space-between"
        className={style.headerPayments}
      >
        <Grid item container direction="column">
          <Grid
            item
            container
            direction="row"
            wrap="nowrap"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography className={style.titleStyle}>
                Antecipação de Valores
              </Typography>
            </Grid>

            {isMobile &&
              ['right'].map(anchor => (
                <Grid item key={anchor}>
                  <Button
                    startIcon={<FilterIcon />}
                    className={style.filterButton}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <p className={style.filterLabel}>Filtrar</p>
                  </Button>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                  >
                    <ContentFilterDrawer
                      setLoading={setLoading}
                      initialDate={initialDate}
                      setInitialDate={setInitialDate}
                      finalDate={finalDate}
                      setFinalDate={setFinalDate}
                      setCurrentPage={setCurrentPage}
                      handleClearFilters={handleClearFilters}
                      anticipationList={anticipationList}
                      setState={setState}
                    />
                  </Drawer>
                </Grid>
              ))}
          </Grid>

          <Grid item>{isMobile ? renderAnticipationFilter() : ''}</Grid>
        </Grid>

        <Hidden only="xs">
          {anticipations?.length > 0 && (
            <div className={styles.buttonPadding}>
              <Button
                ref={anchorRef}
                variant="contained"
                style={styles.buttonStyle}
                startIcon={<DescriptionOutlinedIcon />}
                onClick={handleClick}
                aria-controls="simple-menu"
                aria-haspopup="true"
              >
                <Typography className={styles.buttonTextStyle} display="inline">
                  Exportar
                </Typography>
              </Button>
            </div>
          )}

          <div className={styles.menuList}>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className={classes.menuList}
            >
              <MenuItem onClick={() => pdfDownload()}>
                <span className={style.menuListItem}>PDF</span>
                {loadingDownload && (
                  <CircularProgressWithLabel value={percentage} />
                )}
              </MenuItem>

              <MenuItem onClick={() => csvDownload()}>
                <span className={style.menuListItem}>CSV</span>
                {loadingDownloadCsv && (
                  <CircularProgressWithLabel value={percentage} />
                )}
              </MenuItem>
              <MenuItem onClick={() => xlsDownload()}>
                <span className={style.menuListItem}>XLS</span>
                {loadingDownloadXls && (
                  <CircularProgressWithLabel value={percentage} />
                )}
              </MenuItem>
            </Menu>
          </div>
        </Hidden>
      </Grid>
    );
  };

  if (loading) {
    return <LogoLoading />;
  }

  return (
    <div className={style.anticipationContainer}>
      {anticipations?.length === 0 && changedFilter ? (
        <>
          {renderHeader()}

          {loadingList && !isMobile ? (
            renderTableOrList()
          ) : loadingList ? (
            renderMobileList()
          ) : (
            <>
              <Hidden smDown>{renderFilters()}</Hidden>
              <ResultNotFound
                text="Nenhum Resultado Encontrado"
                alternativeText="Verifique os dados pesquisados e tente novamente."
                buttonText="Limpar Filtros"
                onClick={handleClearFilters}
              />
            </>
          )}
        </>
      ) : (
        <>
          {renderHeader()}
          {isMobile ? renderMobileList() : renderTableOrList()}
        </>
      )}

      <Hidden smUp>
        <FloatButtonDownloadArchives
          renderButton={anticipations?.length > 0}
          pdfDownload={pdfDownload}
          csvDownload={csvDownload}
          xlsDownload={xlsDownload}
          actions={actions}
        />
      </Hidden>
    </div>
  );
};

export default ConsultAnticipation;
