import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      formControl: {
        borderRadius: '40%',
      },
    },
  },
  palette: {
    primary: {
      500: '#a41e1d',
    },
  },
});
