const styles = {
  title: {
    fontWeight: 'bold',
  },

  subTitle: {
    marginTop: '-12px',
    fontWeight: 'normal',
    marginBottom: 20
  },

  subTitleStatus: {
    fontWeight: 600,
  },

  selectText: {
    fontWeight: 500,
    marginTop: '20px',
    marginBottom: '5px',
  },

  textFieldStyle: {
    width: '100%',
    marginBottom: 25
  },

  disabledConfirmButtonStyle: {
    color: '#FFFFFF',
    backgroundColor: '#cf1c1c',
    padding: '10px auto',
    marginRight: '14px',
    marginBottom: '12px',
    opacity: '0.4'
  },

  confirmButtonStyle: {
    color: '#FFFFFF',
    backgroundColor: '#cf1c1c',
    padding: '10px auto',
    marginRight: '14px',
    marginBottom: '12px',
  },

  cancelTextStyle: {
    color: '#a41e1d',
    fontWeight: 500,
    marginBottom: '9.5px',
    marginRight: 4
  },

  infoText: {
    fontSize: '14px',
    fontWeight: 500,
    marginTop: '2px',
    marginBottom: '5px',
    color: '#cf1c1c',
  },
  dateInfoText: {
    fontWeight: 500,
    marginTop: '20px',
    marginBottom: '7px',
    color: 'rgba(0, 0, 0, 0.6)'
  },
  disabledDateInfoText: {
    color: '#bdbdbd',
    fontWeight: 500,
    marginTop: '20px',
    marginBottom: '7px',
  },
  datePickerStyle: {
    width: '100%',
  },
  closeButtonStyle: {
    color: '#FFFFFF',
    backgroundColor: '#a41e1d',
    marginRight: '5px',
    marginBottom: '10px',
  },
  circularLoadingStyle: {
    color: '#FFFFFF',
    width: '20px',
    height: '20px',
    marginLeft: '25px',
    marginRight: '25px',
  },

  buttonsContainer: {
    marginTop: 30
  }
};

export default styles;
