const styles = {
  title: {
    fontFamily: 'DM Sans',
  },
  confirmButtonStyle: {
    color: '#FFFFFF',
    background: '#a41e1d',
    marginRight: '15px',
    marginBottom: '10px',
    marginLeft: '10px',
  },
  disabledConfirmButtonStyle: {
    color: '#FFFFFF',
    background: '#a41e1d',
    marginRight: '15px',
    marginBottom: '10px',
    marginLeft: '10px',
    opacity: 0.5,
  },
  disabledButton: {
    opacity: '0.5',
  },
  inputLabelStyle: {
    marginTop: '1em',
    marginBottom: '1em',
    fontSize: '18px',
    color: '#1B181C',
    letterSpacing: '0.1px',
    fontFamily: 'DM Sans',
  },
  circularLoadingStyle: {
    color: '#FFFFFF',
    width: '20px',
    height: '20px',
    marginLeft: '25px',
    marginRight: '25px',
  },
  errroMessage: {
    color: 'rgb(255, 42, 51)',
    marginTop: '18px',
    marginBottom: '-12px',
    padding: '5px 5px',
    borderRadius: '10px',
  },
  contentDialog: {
    overflow: 'hidden',
  },
  cancelButton: {
    position: 'relative',
    bottom: 5,
  },
  bigText: {
    color: '#000000',
    fontWeight: 500,
    marginBottom: '8px',
    fontSize: '16px',
    textAlign: 'center',
  },
  emailText: {
    color: '#545255',
    fontWeight: 500,
    marginBottom: '15px',
    textAlign: 'center',
  },
  helperText: {
    fontSize: '12px',
    marginTop: '6px',
    textAlign: 'center',
  },
  errorText: {
    fontSize: '12px',
    marginTop: '6px',
    textAlign: 'center',
    color: 'red',
  },
};

export default styles;
