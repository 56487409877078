import React from 'react';

import styles from './styles.module.css';

export default function Badge({
  title,
  color="#cf1c1c"
}) {
  return (
    <div className={styles.badge} style={{ background: color }}>
      {title}
    </div>
  )
}
