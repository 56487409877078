import React from 'react';

import { CircularProgress } from '@material-ui/core';
import styles from './styles.module.css';

function LogoLoading() {
  return (
    <div className={styles.circularProgressContainer}>
      <CircularProgress className={styles.circularProgress} />
    </div>
  );
}

export default LogoLoading;
