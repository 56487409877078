import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: '25px',
    borderRadius: '5px',
    textDecoration: 'none',
  },
  modalTitle: {
    marginBottom: '5px',
    fontFamily: 'DM Sans',
    fontSize: '22px'
  },
  modalText: {
    fontFamily: 'DM Sans',
    fontSize: 16,
    color: '#545255'
  },
  linkModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: '70px'
  },
  linkModalItem: {
    marginLeft: '40px',
    color: '#a41e1d',
    fontFamily: 'DM Sans',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  cancelButton: {
    color: '#a41e1d',
    marginRight: '15px',
    padding: '7px 16px',
    fontFamily: 'DM Sans',
    borderRadius: '4px'
  },
  confirmButton: {
    background: '#a41e1d',
    color: '#ffffff',
    padding: '7px 16px',
    fontFamily: 'DM Sans',
    borderRadius: '4px',
    transition: '.3s',
    '&:hover': {
      textDecoration: 'none',
      background: '#a41e1d',
      opacity: '.95',
    },
  }
}));
