import React from 'react';
import {
  Radio,
  withStyles
} from '@material-ui/core';

const PurpleRadio = withStyles({
  root: {
    color: '#A41E1D',
    '&$checked': {
      color: '#A41E1D',
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

export default PurpleRadio;
