export const styles = {
  subheader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: '50px auto',
  },
  textBigger: {
    fontWeight: 700,
    fontSize: 26,
    color: '#000000',
    marginTop: 20,
  },
  textSmall: {
    fontSize: 18,
    color: '#000000',
  },
  textSmallSubheader: {
    fontSize: 18,
    color: '#000000',
    fontWeight: 500,
  },
  purpleText: {
    color: '#a41e1d',
  },
  textBlueBigger: {
    fontWeight: 500,
    fontSize: 24,
    fontFamily: 'DM Sans',
    color: '#2F80ED',
    marginTop: 5,
  },
  textBlueSmall: {
    fontSize: 16,
    fontFamily: 'DM Sans',
    color: '#2F80ED',
  },
  textGreenBigger: {
    fontWeight: 500,
    fontSize: 24,
    fontFamily: 'DM Sans',
    color: '#219653',
    marginTop: 5,
  },
  textGreenSmall: {
    fontSize: 16,
    fontFamily: 'DM Sans',
    color: '#219653',
  },
  textRedBigger: {
    fontWeight: 500,
    fontSize: 24,
    fontFamily: 'DM Sans',
    color: '#B00020',
    marginTop: 5,
  },
  textRedSmall: {
    fontSize: 16,
    fontFamily: 'DM Sans',
    color: '#B00020',
  },
  textOrangeBigger: {
    fontWeight: 500,
    fontSize: 24,
    fontFamily: 'DM Sans',
    color: '#cf1c1c',
    marginTop: 5,
  },
  smallOrangeBigger: {
    fontSize: 16,
    fontFamily: 'DM Sans',
    color: '#cf1c1c',
  },
  paidStyle: {
    color: '#219653',
    fontFamily: 'DM Sans',
  },
  confirmedStyle: {
    color: '#219653',
    fontFamily: 'DM Sans',
  },
  cancelledStyle: {
    color: '#B00020',
    fontFamily: 'DM Sans',
  },
  inProcessVerificationStyle: {
    color: '#2F80ED',
    fontFamily: 'DM Sans',
  },
  displayStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  alert: {
    display: 'block',
    width: '100%',
  },
  alertContainer: {
    paddingRight: '15px',
    paddingLeft: '15px',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: 'fit-content',
  },
  alertIcon: {
    flat: 'left',
    marginTop: '4px',
  },
  alertText: {
    marginLeft: '6px',
    float: 'right',
    lineHeight: '32px',
  },
};
