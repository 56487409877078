import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Container,
  CssBaseline,
  IconButton,
  Typography
} from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ReactComponent as LogoEmpresa } from '../../../assets/Logo_Branco.svg';
import { ReactComponent as ArrowBack } from '../../../assets/arrow_back.svg';
import styles from './styles.module.css';

const EmailAndCpf = () => {
  const history = useHistory();

  const onButtonClicked = () => {
    history.push('/login')
  };

  const onBackButtonClicked = () => {
    history.push('/reset-password/validate-token');
  }

  return (
    <div
      className={styles.pageContainer}
    >
      <MuiThemeProvider>
        <Container>
          <CssBaseline />
            <div className={styles.paper}>
              <IconButton
                className={styles.arrowBack}
                onClick={onBackButtonClicked}
              >
                <ArrowBack />
              </IconButton>
              <div>
                <LogoEmpresa width={150} height="fitContent" />
              </div>
              <form className={styles.form}>
                <div className={styles.content}>
                  <Typography className={styles.textBigger}>
                    Solicitar ajuda para acessar sua conta.
                  </Typography>
                  <Typography className={styles.textSmall}>
                    Caso não consiga recuperar sua conta com as opções disponíveis, entre em contato com o suporte:
                  </Typography>
                </div>
                <div className={styles.contentPhones}>
                  <Typography className={styles.textSmall}>
                    Capital e região metropolitana: <span className={styles.textSmallViollet}>(85) 3215-6667 / 6668 / 6651</span> <br />
                    Demais Regiões: <span className={styles.textSmallViollet}>0800 006 6670</span><br />
                    (7h às 20h, segunda a sábado, incluindo feriados)
                  </Typography>
                  <Typography className={styles.textSmall}>
                    Atendimento: <span className={styles.textSmallViollet}>atendimentocorporativo@somapay.com.br</span>
                  </Typography>
                </div>
                <div className={styles.submitArea}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={styles.submit}
                    onClick={onButtonClicked}
                  >
                    Login
                  </Button>
                </div>
              </form>
            </div>
        </Container>
      </MuiThemeProvider>
    </div>
  );
};

export default EmailAndCpf;
