import { makeStyles } from '@material-ui/core/styles';

export const styles = {
  textStyle: {
    fontSize: '0.7rem',
    color: '#707070',
  },
  situationCell: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginLeft: '9px',
  },
  nameRegenerate: {
    marginRight: '8px',
  },
  menuItemRegenerate: {
    widht: '207px !important',
  },
  tableHeadLabel: {
    fontSize: 14,
    fontWeight: 500,
  },
};

export const useStyles1 = makeStyles(theme => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export const useStylesTable = makeStyles(theme => ({
  checkIcon: {
    color: '#a41e1d',
  },
}));
