import { makeStyles, createStyles } from '@material-ui/core/styles';

const styles = {
  titleStyle: {
    fontSize: 34,
    fontWeight: 700
  },
  contentStyle: {
    marginTop: '40px',
  },
  biggerTextStyle: {
    fontSize: 18,
    fontWeight: 700,
  },
  smallerTextStyle: {
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  buttonStyle: {
    height: '18vh',
    width: '100%',
  },
  padding: {
    padding: 10,
  },
  primaryTextSTyle: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 500,
    letterSpacing: '0.1px',
    textTransform: 'none',
    padding: 10,
  },
  secondaryTextStyle: {
    fontFamily: 'DM Sans',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textTransform: 'none',
    padding: 10,
  },
  radioButtonStyle: {
    color: '#cf1c1c',
  },
  errorListMargin: {
    marginBottom: '5%',
  },
  infosContainer: {
    marginTop: 40,
    borderRadius: 6,
    border: '1px solid #F2F2F2',
    background: '#FFFFFF',
    padding: 20,
    marginBottom: '-15px',
  },
  infosBlock: {
    display: 'flex',
    marginTop: 10
  },
  info: {
    marginRight: 40
  },
  labelInfo: {
    color: '#808080',
    fontSize: 12,
    marginBottom: 2,
  },
  infoContent: {
    color: '#000000',
    fontWeight: 500
  }
};

export default styles;

export const useStyles = makeStyles(theme =>
  createStyles({
    divider: {
      display: 'flex',
      marginTop: '30px',
      marginLeft: '-22px',
      marginRight: '-22px',
      wordBreak: 'break-all',
    },
  })
);
