import React, { useState } from 'react';

import Carousel from 'nuka-carousel';

import { Dialog } from '@material-ui/core';

import { ReactComponent as ArrowRight } from '../../assets/arrow-right-figma-model.svg';
import { ReactComponent as ArrowLeft } from '../../assets/arrow-left-figma-model.svg';
import WelcomePOCard from './UI/WelcomePOCard';

import { welcomeModalData } from '../../mocks/welcomeModalData';

import styles from './styles.module.css';

function WelcomeModal({ open, onConfirmClicked }) {
  const [pages, setPages] = useState(1);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      PaperProps={{
        style: {
          width: '360px',
          borderRadius: '12px',
        },
      }}
    >
      <Carousel
        withoutControls={pages === 7}
        disableEdgeSwiping
        navButtonsAlwaysVisible
        defaultControlsConfig={{
          nextButtonText: (
            <ArrowRight
              onClick={() => setPages(prevState => prevState + 1)}
              className={styles.arrow}
            />
          ),
          prevButtonText: (
            <ArrowLeft
              onClick={() => setPages(prevState => prevState - 1)}
              className={styles.arrow}
            />
          ),
          pagingDotsStyle: {
            fill: '#A41E1D',
            outline: 'none',
            marginBottom: '16px',
          },
          prevButtonStyle: {
            background: 'transparent',
            marginTop: '368px',
            marginLeft: '80px',
            outline: 'none',
          },
          nextButtonStyle: {
            background: 'transparent',
            marginTop: '368px',
            marginRight: '80px',
            outline: 'none',
          },
        }}
      >
        {welcomeModalData.map(modalData => {
          return (
            <WelcomePOCard
              key={modalData?.key}
              welcomeImage={modalData?.image}
              title={modalData?.title}
              subTitle={modalData?.subtitle}
              lastCard={modalData?.key === 7}
              onConfirmClicked={onConfirmClicked}
            />
          );
        })}
      </Carousel>
    </Dialog>
  );
}

export default WelcomeModal;
