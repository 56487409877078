import { itemsProfilesAllowed } from './itemsProfilesAllowed';

export function itemsDisabledValidation(
  accessProfiles,
  payrollProduct,
  safeProduct,
  isSubsidiary,
  situationPointPlan,
  consigned,
  beeValeBenefits,
) {
  const profilesMatch = profilesAllowed =>
    !Object.keys(profilesAllowed)?.some(
      profile => accessProfiles?.indexOf(profile) !== -1,
    );

  const isOptionsDisabled = {
    cashIn: profilesMatch(itemsProfilesAllowed.cashIn) || isSubsidiary,
    payment: profilesMatch(itemsProfilesAllowed.payment) || !payrollProduct,
    bankExtract: profilesMatch(itemsProfilesAllowed.bankExtract),
    manageAccounts: profilesMatch(itemsProfilesAllowed.manageAccounts),
    pix: profilesMatch(itemsProfilesAllowed.pix) || isSubsidiary,
    valuesQuery:
      profilesMatch(itemsProfilesAllowed.valuesQuery) || isSubsidiary,
    manageEmployees:
      profilesMatch(itemsProfilesAllowed.manageEmployees) || !payrollProduct,
    paycheck: profilesMatch(itemsProfilesAllowed.paycheck) || !payrollProduct,
    valuesAnticipation:
      profilesMatch(itemsProfilesAllowed.valuesAnticipation) || isSubsidiary,
    limitQuery: profilesMatch(itemsProfilesAllowed.limitQuery) || isSubsidiary,
    costCenterAndSector: profilesMatch(
      itemsProfilesAllowed.costCenterAndSector,
    ),
    manageUsers: profilesMatch(itemsProfilesAllowed.manageUsers),
    myPlanSomapay: profilesMatch(itemsProfilesAllowed.myPlanSomapay),
    somapayAcademy: profilesMatch(itemsProfilesAllowed.somapayAcademy),
    collaborators:
      profilesMatch(itemsProfilesAllowed.collaborators) || !situationPointPlan,
    myPoint: profilesMatch(itemsProfilesAllowed.myPoint),
    invoices:
      profilesMatch(itemsProfilesAllowed.invoices) || !situationPointPlan,
    insurance: profilesMatch(itemsProfilesAllowed.insurance) || !safeProduct,
    consigned: profilesMatch(itemsProfilesAllowed.consigned) || !consigned,
    cashOut: profilesMatch(itemsProfilesAllowed.cashOut),
    beevaleBenefits:
      profilesMatch(itemsProfilesAllowed.beevaleBenefits) || !beeValeBenefits,
  };

  return { ...isOptionsDisabled };
}
